<div class="row align-items-center">
    <div class="col-lg-2 col-12 col-sm-3 col-md-3"></div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/new-form-academy-partner-5_1.png" alt="image">
        </div>
    </div>

    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/unidav.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/neaform.jpg" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/new-form-academy-partner-6_1.png" alt="image">
        </div>
    </div>
</div>
